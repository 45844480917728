const useScrollToTop = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const scrollToTopInPromoteFlows = () => {
    document.getElementById('number-stepper')?.scrollIntoView({ behavior: 'auto', block: 'start', inline: 'nearest' });
  };

  return {
    scrollToTop: scrollToTop,
    scrollToTopInPromoteFlows: scrollToTopInPromoteFlows,
  };
};

export default useScrollToTop;
