import React from 'react';

import useBreakpoints from '@/hooks/utility/useBreakpoints';

const NumberStepper = ({ stepNumber, steps }: { stepNumber: number; steps: number }) => {
  const { breakpointHit } = useBreakpoints();

  return (
    <div id="number-stepper" className={`stepper-container m-auto ${breakpointHit ? 'gap8' : ''}`}>
      {[...Array(steps)].map((_, step) => (
        <div
          key={step}
          className={`step-container ${step + 1 !== steps ? 'flex-grow' : ''}`}
          style={{ '--animation-number': `${step}` } as React.CSSProperties}
        >
          {!breakpointHit && (
            <div className={`step ${stepNumber === step + 1 ? 'active' : stepNumber > step + 1 ? 'has-been' : ''}`}>
              <div className="step-inner">{step + 1}</div>
            </div>
          )}
          {step + 1 !== steps && (
            <div
              className={`line ${stepNumber === step + 1 ? 'active' : stepNumber > step + 1 ? 'has-been' : ''}`}
            ></div>
          )}
        </div>
      ))}
    </div>
  );
};

export default NumberStepper;
