import React, { useState } from 'react';

import { Button, Icon } from '@mui/material';

import useScrollToTop from '@/hooks/utility/useScrollToTop';

const ScrollToTopButton = () => {
  const [showScrollToTopButton, setShowScrollToTopButton] = useState<boolean>(false);
  const { scrollToTop } = useScrollToTop();

  window.onscroll = () => {
    if (window.scrollY > 500) {
      setShowScrollToTopButton(true);
    } else {
      setShowScrollToTopButton(false);
    }
  };

  return (
    <>
      {showScrollToTopButton && (
        <Button
          className="scroll-to-top-button"
          onClick={() => {
            scrollToTop();
          }}
        >
          <Icon>arrow_upward</Icon>
        </Button>
      )}
    </>
  );
};

export default ScrollToTopButton;
