import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Autocomplete, TextField } from '@mui/material';

import useSnackbarContext from '@/hooks/context/useSnackbarContext';
import { SpotifyArtistSearchModel } from '@/models/Spotify';
import SpotifyAPI from '@/network/SpotifyAPI';
import { handleApiError } from '@/utility/api';

const SpotifySearchArtist = ({
  spotifyArtist,
  isChangeArtist = false,
}: {
  spotifyArtist: (artist: SpotifyArtistSearchModel) => void;
  isChangeArtist?: boolean;
}) => {
  const { t } = useTranslation();
  const noImage = '/images/profile-placeholder.svg';
  const { dispatchSnackbar } = useSnackbarContext();

  const [chosenArtist, setChosenArtist] = useState<SpotifyArtistSearchModel>({ id: '', name: '' });
  const [searchOptions, setSearchOptions] = useState<SpotifyArtistSearchModel[]>([
    {
      name: t('SPOTIFY-SEARCH.START-TYPING'),
      images: [{ url: noImage }],
      id: '',
    },
  ]);

  const searchSpotify = async (query: string) => {
    try {
      if (!query) return;

      const response = await SpotifyAPI.searchArtist({ query });
      setSearchOptions(response.data.artists.items);
    } catch (error: unknown) {
      handleApiError({ error, dispatchSnackbar, customMessage: 'Error searching Spotify' });
    }
  };

  const handleChosenArtist = (value?: SpotifyArtistSearchModel | null) => {
    if (!value) return;

    setChosenArtist(value);
    spotifyArtist(value);
  };

  return (
    <>
      <label>
        <p>{isChangeArtist ? t('SPOTIFY-SEARCH.CHANGE-SPOTIFY-ARTIST') : t('SPOTIFY-SEARCH.FIND-SPOTIFY-ARTIST')}</p>
        <Autocomplete
          options={searchOptions}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={() => true}
          value={chosenArtist}
          onChange={(_, value) => handleChosenArtist(value)}
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              {option.images && option.images.length > 0 ? (
                <img className="search-list-image" src={option.images[0]?.url} alt="" />
              ) : (
                <img className="search-list-image" src={noImage} alt="" />
              )}
              <span className="pl16 text-white">{option.name}</span>
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              onChange={async (event: ChangeEvent<HTMLInputElement>) => await searchSpotify(event.target.value)}
              placeholder={t('SPOTIFY-SEARCH.SEARCH-FOR-AN-ARTIST')}
              sx={{
                '& .MuiInputLabel-outlined': {
                  paddingLeft: '20px',
                  borderColor: 'white',
                },
                '& .MuiInputLabel-shrink': {
                  marginLeft: '20px',
                  paddingLeft: '10px',
                  paddingRight: 0,
                  borderColor: 'white',
                },
                '& .MuiAutocomplete-listbox': {
                  maxHeight: 200,
                  overflow: 'auto',
                },
              }}
              InputLabelProps={params}
            />
          )}
        />
      </label>
    </>
  );
};

export default SpotifySearchArtist;
