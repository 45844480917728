import axios, { isAxiosError } from 'axios';

import { SnackbarModel } from '@/contexts/SnackBarContext';

import { handleUnknownError, isDevelopment } from './generic';

export const initApi = (baseUrl: string) => {
  axios.defaults.baseURL = baseUrl;
  axios.defaults.headers.common['Platform-Type'] = 'Unhurd/Web';
};

export const initToken = (token?: string) => {
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    axios.defaults.headers.common['Authorization'] = '';
  }
};

export const handleApiError = ({
  error,
  dispatchSnackbar,
  customMessage,
}: {
  error: unknown;
  dispatchSnackbar?: (value: SnackbarModel) => void;
  customMessage?: string;
}) => {
  if (!dispatchSnackbar) {
    if (isDevelopment) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
    return;
  }

  if (customMessage) {
    dispatchSnackbar({
      type: 'OPEN_SNACKBAR',
      payload: {
        message: customMessage,
        type: 'error',
      },
    });
    return;
  }

  if (isAxiosError(error) && error.response?.data.errorMessage) {
    dispatchSnackbar({
      type: 'OPEN_SNACKBAR',
      payload: {
        message: error.response.data.errorMessage,
        type: 'error',
      },
    });
    return;
  }

  handleUnknownError(dispatchSnackbar);
};
