import React from 'react';

const ActiveCampaignsStatus = ({ text, color }: { text: string; color?: string }) => {
  return (
    <div className={`percentage-difference-container ${color || 'blue'}`}>
      <p className={`status-text ${color || 'blue'}`}>{text}</p>
    </div>
  );
};

export default ActiveCampaignsStatus;
