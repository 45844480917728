import React from 'react';

import { Button } from '@mui/material';

const CookiePopup = ({ onClose }: { onClose: () => void }) => {
  return (
    <>
      <div className="cookie-popup">
        <div className="cookie-popup-content">
          <h2 className="text-white">Cookie Policy</h2>
          <p className="text-white">
            This website uses cookies to ensure you get the best experience on our website. View our{' '}
            <a href="https://www.unhurd.co.uk/privacy-policy" target="blank">
              privacy policy
            </a>{' '}
            for more information.
          </p>
          <div className="cookie-popup-buttons">
            <Button
              className="cookie-popup-button"
              onClick={() => {
                localStorage.setItem('cookieChoice', 'false');
                onClose();
              }}
            >
              Decline
            </Button>
            <Button
              className="cookie-popup-button btn-white"
              onClick={() => {
                localStorage.setItem('cookieChoice', 'true');
                onClose();
              }}
            >
              Accept
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CookiePopup;
