import React from 'react';

import { Button } from '@mui/material';

import useAccountContext from '@/hooks/context/useAccountContext';

const TikTokLoginButton = ({ isWide }: { isWide?: boolean }) => {
  const { refetchAccount } = useAccountContext();
  const redirect_uri = `${window.location.origin}/tiktok-callback`;
  const appID = import.meta.env.VITE_TIKTOK_APP_ID;
  const state = '';

  const route = `https://business-api.tiktok.com/portal/auth?app_id=${appID}&state=${state}&redirect_uri=${redirect_uri}`;

  const loginToTikTok = () => {
    window.open(`${route}`, '', 'popup=true,width=500px,height=800px');
    window.addEventListener(
      'message',
      (event) => {
        if (event.data === 'TikTok logged in') {
          refetchAccount();
        }
      },
      true
    );
  };

  return (
    <>
      <Button className={`m0 ${isWide ? 'w100p mt10' : ''}`} onClick={loginToTikTok}>
        Login to TikTok
      </Button>
    </>
  );
};

export default TikTokLoginButton;
