import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import { Icon } from '@mui/material';

import { navItems } from '@/constants/NavModel';
import useActiveCampaigns from '@/hooks/campaigns/useActiveCampaigns';
import useIntercomContext from '@/hooks/context/useIntercomContext';

import Logout from '../auth/Logout';
import CustomIcons from '../microcomponents/CustomIcons';

const NavBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { activeCampaigns } = useActiveCampaigns();

  const [minimize] = useState<boolean>(false);
  const [breakPointHit, setBreakPointHit] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<number>(0);
  const [showIntercom, setShowIntercom] = useState<boolean>(false);

  const { isOpen, dispatchActivateIntercom, dispatchDeactivateIntercom, dispatchOpenIntercom } = useIntercomContext();

  const handleIntercom = () => {
    setShowIntercom(true);
    dispatchActivateIntercom('navbar');
    dispatchOpenIntercom();
  };

  useEffect(() => {
    if (!isOpen) {
      setShowIntercom(false);
    }
  }, [dispatchOpenIntercom, isOpen, location.pathname, showIntercom]);

  useEffect(() => {
    if (!showIntercom) {
      dispatchDeactivateIntercom('navbar');
    }
  }, [dispatchDeactivateIntercom, showIntercom]);

  useEffect(() => {
    return () => {
      dispatchDeactivateIntercom('navbar');
    };
  }, [dispatchDeactivateIntercom]);

  const handleResize = () => {
    window.visualViewport && window.visualViewport.width <= 900 ? setBreakPointHit(true) : setBreakPointHit(false);
  };

  useEffect(() => {
    if (activeCampaigns) {
      const total = activeCampaigns?.playlistPitches?.recentlyReviewed + activeCampaigns?.socialAds?.active;
      setNotifications(total);
    }
  }, [activeCampaigns]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const excludedPaths = [
    '/login',
    '/payment-confirmation/meta',
    '/onboarding',
    '/email-verification',
    '/payment-confirmation/tiktok',
    '/payment-confirmation/playlist',
    '/payment-confirmation/subscription',
  ];

  if (excludedPaths.includes(location.pathname)) return null;

  return (
    <>
      {!breakPointHit && (
        <div className={minimize ? 'nav-container minimized' : 'nav-container'}>
          <div onClick={() => navigate('/')}>
            <img
              className="cursor-pointer logo-img"
              data-testid="main-logo"
              src={minimize ? '/images/logos/unhurd-logo.png' : '/images/logos/full-text-logo.svg'}
              alt="unhurd-logo"
            />
          </div>
          <nav className="nav-sidebar">
            {navItems.map((item) => (
              <div key={item.title} className="min-h48" data-testid={`nav-bar-item-${item.name}`}>
                <NavLink
                  className={minimize ? 'nav-link-item minimized' : 'nav-link-item'}
                  data-testid={`nav-item-${item.name}`}
                  to={item.navLink}
                >
                  <CustomIcons className="nav-link-icon material-symbols-outlined" name={item.icon} />
                  <span className={minimize ? 'nav-link-text minimize' : 'nav-link-text'}>{t(item.title)}</span>
                  {item.notifications && notifications > 0 && <div className="nav-notification">{notifications}</div>}
                </NavLink>
              </div>
            ))}
          </nav>
          <div className={minimize ? 'nav-footer minimize' : 'nav-footer'}>
            <div className={`logout-button ${showIntercom ? '' : 'text-faded'}`} onClick={handleIntercom}>
              <Icon className={`logout-icon`}>chat</Icon>
              {!minimize && (
                <p className={`logout-text ${showIntercom ? '' : 'text-faded'}`}>{t('SETTINGS.SUPPORT')}</p>
              )}
            </div>
            <Logout minimize={minimize} />
          </div>
        </div>
      )}
    </>
  );
};

export default NavBar;
