import React from 'react';
import { useTranslation } from 'react-i18next';

import Icon from '@mui/material/Icon';

import useAccountContext from '@/hooks/context/useAccountContext';

const Logout = ({ minimize, inButton }: { minimize?: boolean; inButton?: boolean }) => {
  const { t } = useTranslation();

  const { logoutUser } = useAccountContext();

  return (
    <div
      className={`${minimize ? 'logout-button minimize' : 'logout-button'}  `}
      onClick={() => {
        logoutUser();
      }}
      data-testid="sign-out-button"
    >
      <Icon className={`logout-icon" ${inButton ? 'pt0' : ''}`}>logout</Icon>
      {!minimize && <p className={`logout-text text-white ${inButton ? 'small pt4' : ''} `}>{t('COMMON.SIGN-OUT')}</p>}
    </div>
  );
};

export default Logout;
